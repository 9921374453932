.green{
    color: green;
    font-weight: bold;
}
.grey{
    color: gray;
    font-weight: bold;
}
.blue{
    color: rgb(52, 69, 145);
    font-weight: bold;
}


/* file upload button */
input[type="file"]::file-selector-button {
  border-radius: 4px;
  padding: 0 16px;
  height: 40px;
  cursor: pointer;
  /* background-color: white; */
  background-color: rgba(99, 102, 241, var(--tw-text-opacity));
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  margin-right: 16px;
  transition: background-color 200ms;
  color: white;
}

/* file upload button hover state */
input[type="file"]::file-selector-button:hover {
  background-color: #f3f4f6;
  color: black;
}

/* file upload button active state */
input[type="file"]::file-selector-button:active {
  background-color: #e5e7eb;
  color: black;
}

