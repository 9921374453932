.docs-code-preview .token.tag.token.tag,
.docs-code-preview .token.tag.token.tag.token.punctuation {
  color: #a8d0ff;
}
.docs-code-preview .token.tag.token.attr-name {
  color: #f1a19e;
}
.docs-code-preview .token.string,
.docs-code-preview .token.tag.token.attr-value,
.docs-code-preview .token.tag.token.script.language-javascript.token.string {
  color: #78d092;
}
.docs-code-preview .token.keyword,
.docs-code-preview .token.tag.token.script.language-javascript {
  color: #fff;
}
.docs-code-preview .token.keyword.module {
  color: #b586c3;
}
.docs-code-preview .token.maybe-class-name,
.docs-code-preview .token.tag.token.tag.token.class-name {
  color: #e4bd77;
}
.docs-code-preview .token.comment {
  color: #7d7d7d;
}
.ct-docs-disable-nav-fixed nav.fixed{
  position: absolute!important;
  top: 0;
  left: 0;
}
.ct-docs-disable-sidebar-fixed nav.fixed{
  position: unset!important;
  top: 0;
}
.ct-docs-disable-sidebar-content nav.fixed{
  position: unset!important;
}
.ct-docs-disable-sidebar-content div.md\:ml-64{
  margin-left: -16rem!important;
  width: 100%;
}
@media (min-width: 768px) {
  .ct-docs-disable-sidebar-content div.md\:ml-64{
    margin-left: 0!important;
    width: calc(100% - 16rem);
  }
}
.docs-components-wrapper [id]::before{
  display: block;
  height: 6rem;
  margin-top: -6rem;
  content: "";
}
.dzu-dropzone {
  border: none !important;
  overflow-x: hidden !important;
}
.dzu-inputLabel {
  color: #b0b2b5 !important;
}
.preview-box {
  display:flex;
  align-items:center;
  width: calc(100% - 30px);
  padding: 10px 3%;
  background: #fff;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}

.preview-box img {
  max-height: 80px;
  max-width: 80px;
  border-radius: 4px;
  margin-right: 10px;
}

.preview-box .name {
  font-style: italic;
  color: #666;
  margin-right: 7px;
}

.preview-box .percent {
  font-weight: 600;
}

.preview-box .status {
  margin-left: 7px;
  font-weight: 600;
  text-transform: uppercase;
}


/* .input {
  width: 100%;
  height: 45px;
  padding: 0 1rem;
  margin-top: 1rem;
  box-sizing: border-box;
  font: inherit;
  border-radius: 0.2rem;
  border: 2px solid #d4d5d6;
  color: #565656;
  -webkit-appearance: none;
} */

.input:focus {
  border-color: cornflowerblue;
  outline: none;
}

.input.has-error {
  border-color: tomato;
}

.error {
  margin: 0;
  font-size: 90%;
  color: tomato;
}

.tag-item {
  background-color: #efefef;
  display: inline-block;
  font-size: 10px;
  border-radius: 30px;
  /* height: 100%; */
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
  max-height: 10%;
}
.tag-item-list {
  overflow: auto;
  max-height: 11rem;
}

.tag-item > .button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #e4e4e7;
}

.searchBox:focus {
  box-shadow: none !important;
}
/* .searchBox:focus {
  outline: none;
} */
.modal-popup{
  max-width: 60em !important;
}
.multi-select{
  padding-bottom: 1em !important;
}

.sk-cube-grid {
  width: 40px;
  height: 40px;
  margin: 50px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #4f52c1;
  /* background-color: rgb(175, 166, 166); */
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
          animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; 
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1); 
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  } 
}

.icons {
  display: flex;
  flex-wrap: wrap;
}
.icon {
  width: 48px;
  margin: 16px;
}
.react-lock-screen__ui {
  width: 300px;
  position: fixed;

  /* border: 4px solid rgb(189, 185, 185); */
  /* background: #fff; */
  text-align: center;
  /* padding: 16px; */
  border-radius: 4px;
  top: calc(30% - 152px / 2);
  left: calc(49% - 300px / 2);
}
.react-lock-screen-custom {
  filter: blur(4px);
  user-select: none;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
}